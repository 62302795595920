<template>
  <div class="checkout-container">

    <div class="checkout-container-2" v-if="pageLoaded">

      <div v-if="step === 1">
        <div class="checkout-inner">
          <p class="checkout-header">Paketini Özelleştir</p>
        </div>
        <div class="checkout-inner">

          <div class="section-1">
            <div class="product-info-body">
              <div class="info-inner">
                <router-link :to="'/'+sellername+'/'+slug+'-'+gig_id">
                  <img :src="imageUrl" class="image"/>
                </router-link>

                <div class="column">
                  <div class="info-1">
                    <p class="description">{{description}}</p>
                    <div class="quantity-body" v-if="extraWord && !extraWord.show">
                      <p class="quantity-title" style="align-self: flex-start;">İş Adedi</p>
                      <select class="super-drop quantity" v-model="productQuantity" style="margin-top: -16px;">
                        <option v-for="x in 20" :value="x" :key="x">{{x}}</option>
                      </select>
                    </div>

                    <p class="total">{{productQuantity * total}} TL</p>
                  </div>
                  <div v-if="sellerRating.commentCount > 0" class="row">
                    <star-rating :rating="sellerRating.commentRating"
                                 :max-rating="5"
                                 :star-size="12"
                                 :show-rating="false"
                                 inactive-color="#dce1e1"
                                 active-color="#ffb700"
                                 :read-only="true"></star-rating>
                    <p class="commentRating">{{sellerRating.commentRating}}</p>
                    <p class="commentCount" v-if="sellerRating.commentCount > 0">({{sellerRating.commentCount}} yorum)</p>
                  </div>
                  <p v-if="package.title" class="detail-open-button" @click="detailOpened = !detailOpened">Paket içeriği <span> {{detailOpened? '&#x25B2' : '&#x25BC'}}</span></p>

                </div>
              </div>

              <div class="package-detail" v-if="detailOpened">
                <p class="title">{{package.title}}</p>
                <p class="description">{{package.description}}</p>

                <div class="scope-body">
                  <div class="content-scope" v-for="(option, index) in package.selectedOptions" :key="'o1_' + index">
                    <img class="content-img" src="https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                    <p class="content-title">{{option}}</p>
                  </div>
                  <div class="content-scope" v-for="(option, index) in extra" :key="'o2_' + index" v-if="option.selected">
                    <img class="content-img" src="https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                    <p class="content-title" style="font-weight: 600">{{option.title}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!--extra word-->
            <div class="product-extra-body" v-if="extraWord && extraWord.show" style="margin-bottom: 0px;">
              <table class="extras-table" cellspacing="0">
                <tr>
                  <td class="t-1">
                    <div class="row">
                      <p class="header">
                        Kelime Sayısı
                      </p>
                    </div>
                    <div style="margin-top: 6px;">
                      <span style="font-size: 14px; color: #8b95a1; line-height: 19px; font-weight: 400;">İlk {{extraWord.initial_words}} kelimeden sonraki her {{extraWord.words}} kelime için +{{extraWord.price}} TL eklenir. Metindeki kelime sayısını tam olarak girdikten sonra toplam fiyat otomatik olarak hesaplanır.</span>
                    </div>

                  </td>
                  <td class="t-3">
                    <div class="row">
                      <span style="font-size: 14px; font-weight: 500; color: #2d3640; margin-right: 13px;">Sayı: </span>
                      <input v-model="words" type="number" style="width: 80px">
                    </div>

                  </td>
                </tr>
              </table>
            </div>

            <div v-if="extras && extras.length && extras.length > 0" class="product-extra-body">
              <h4 class="card-header">Ekstra Ekleyin</h4>
              <table class="extras-table" cellspacing="0">
                <tr v-for="e in extras" :key="e.id">
                  <td class="t-1">
                    <div class="row">
                      <div class="checkbox-box"
                           @click="handleCheckBoxData(e, 'checkbox')">
                        <img v-if="extra['selected_' + e.id].selected" class="checkbox-img"
                             :src="extra['selected_' + e.id].selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
                      </div>

                      <p v-if="!e.isFastDelivery" class="header"><span v-if="e.value" style="padding-right: 5px;">{{e.value}}</span>{{e.title}}
                        <span v-if="e.duration && e.duration > 0" style="font-size:13px;font-weight: 400; color: #8b95a1">(+{{e.duration}} gün)</span>
                      </p>
                      <p v-else class="header">Sadece {{e.duration}} günde süper hızlı teslimat</p>
                    </div>
                    <div style="margin-top: 6px;margin-left: 32px;">
                      <span style="font-size: 14px; color: #8b95a1; line-height: 19px; font-weight: 400;">{{e.description}}</span>
                    </div>

                  </td>
                  <td class="t-2" v-if="extra['selected_' + e.id].selected && !e.isFastDelivery">
                    <div class="row">
                      <p class="quantity-title">Adet</p>
                      <select class="super-drop quantity" v-model="extra['selected_' + e.id].quantity" @change="handleCheckBoxData(e, 'duration')">
                        <option v-for="x in 20" :value="x" :key="x">{{x}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="t-2" v-else>&nbsp;</td>
                  <td class="t-3">
                    <p class="total">{{extra['selected_' + e.id].price * extra['selected_' + e.id].quantity }} TL</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="section-2">
            <div class="product-summary-body">
              <h4 class="card-header">Özet</h4>
              <div class="summary-items">
                <p class="item-title">Toplam Fiyat</p>
                <p class="item-value">{{getTotal}} TL</p>
              </div>

              <div class="summary-items">
                <p class="item-title">Toplam Teslim Süresi</p>
                <p class="item-value">{{getDuration}} gün</p>
              </div>

              <button :disabled="waitingResponse" class="super-button summary-button" @click="buyNow()">
                <span>Siparişi Tamamla</span>
              </button>
              <span class="tamamla-help-text">Henüz sizden bir ödeme alınmayacak.</span>
            </div>
            <div style="flex-direction: row; align-items: center; margin-top: 16px;width: 100%; text-align: center ">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_visa.svg">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mastercard.svg">
            </div>
            <div style="text-align: center; margin-top: 16px;">
              <p style="font-weight: 600; color: #2d3640"><img style="width: 11px;" src="https://gcdn.bionluk.com/site/icon/ic_lock_2.svg"> <span style="padding-left:4px;padding-right:4px;color: #00a575">SSL</span> GÜVENLİ ÖDEME</p>
              <p style="font-weight: 400; font-size: 11px; margin-top: 6px;">Bilgilerin 256-bit SSL sertifikası ile şifrelenmektedir</p>
            </div>

          </div>

        </div>
      </div>

      <div v-if="step === 2">
        <div class="checkout-inner">
          <p class="checkout-header">Ödeme Bölümü</p>
        </div>
        <div class="checkout-inner">

          <div class="section-1">


          </div>

          <div class="section-2">
            <div class="product-summary-body">
              <h4 class="card-header">Özet</h4>
              <div class="summary-items">
                <p class="item-title">Toplam Fiyat</p>
                <p class="item-value">{{getTotal}} TL</p>
              </div>

              <div class="summary-items">
                <p class="item-title">Teslim Süresi</p>
                <p class="item-value">{{getDuration}} gün</p>
              </div>

              <button class="super-button summary-button" @click="buyNow()">
                <span>Siparişi Tamamla</span>
              </button>
              <span class="tamamla-help-text">Henüz sizden bir ödeme alınmayacak.</span>
            </div>

          </div>

        </div>
      </div>


    </div>

  </div>
</template>

<script>
  export default {
    name: "src-pages-body-checkout-v2",
    data() {
      return {

        waitingResponse: false,
        step: 1,

        orderID: null,
        pageLoaded: false,
        paymentType: null,
        total: null,
        extras: [],
        imageUrl: null,
        description: null,
        slug: null,
        sellername: null,
        gig_id: null,
        sellerRating: {commentCount:0, commentRating:0},
        package: {},
        detailOpened: false,

        extra: {},
        duration: null,
        productQuantity: 1,
        extraWord: null,
        words: null
      }
    },
    methods: {
      checkout(orderID) {
        this.pageLoaded = false;
        this.orderID = orderID;
        this.api.orders.checkout(this.orderID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.total = result.data.total;
              this.imageUrl = result.data.image_url;
              this.extras = result.data.extras;
              this.description = result.data.description;
              this.slug = result.data.slug;
              this.gig_id = result.data.gig_id;
              this.sellername = result.data.seller_name;
              this.sellerRating = result.data.sellerRating;
              this.package = result.data.package;
              this.duration = result.data.duration;
              this.extraWord = result.data.extra_words;
              this.words = this.extraWord ? this.extraWord.selected_word_count : null
              this.productQuantity = result.data.quantity;

              this.extras.forEach(e => {
                this.extra['selected_' + e.id] = {
                  ...e,
                }
              });

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data));
              this.trackConversionForCheckoutedOrders(this.total, "TRY");
            } else {
	            if(result && result.message === 'paymentsucceed'){
		            this.$router.push('/orders/'+this.$store.state.routerParams[0]);
	            } else {
		            this.$toasted.global.errorToast({description: result.message});
	            }

            }
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      handleCheckBoxData(obj, type) {
        let extra = {...this.extra};
        const key = 'selected_' + obj.id;
        if (type === 'checkbox') {
          extra[key] = {
            ...extra[key],
            selected: !extra[key].selected
          };
        } else if (type === 'duration') {
          extra[key] = {
            ...extra[key]
          };
        }
        this.extra = {...extra};
      },

      buyNow() {

        this.waitingResponse = true;
        const selectedExtras = [];
        for (const key in this.extra) {
          if (this.extra[key].selected) {
            selectedExtras.push(
              {
                id: this.extra[key].id,
                quantity: this.extra[key].quantity,
                title: this.extra[key].title,
                description: this.extra[key].description,
                price: this.extra[key].price,
                duration: this.extra[key].duration
              }
            )
          }
        }
        const requestObj = {
          order_id: this.orderID,
          quantity: this.productQuantity,
          words: this.words,
          selectedExtras: selectedExtras
        };


        this.api.orders.editOrder(this.orderID, this.productQuantity, this.words, JSON.stringify(selectedExtras), this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: result}});
              let checkoutPageProps = result.data;
              checkoutPageProps.whereFrom = 'checkout';
              this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(checkoutPageProps), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
              this.trackConversionForCheckoutedOrders(result.data.total, "TRY");
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }

            this.waitingResponse = false;
          })
          .catch(err => {
            console.log(err, "<<");
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.waitingResponse = false;
          });


      }
    },
    computed: {
      getTotal() {
        let total = 0;
        if(!this.extraWord || !this.extraWord.show) {
          total = this.total
        } else {
          const word = !parseInt(this.words) ? 0 :  parseInt(this.words);
          const wordMultiplier = Math.ceil( (word - this.extraWord.initial_words) / this.extraWord.words);
          total = (total + this.total);
          total = wordMultiplier > 0 ? total + (wordMultiplier * this.extraWord.price) : total;
        }

        total = total * this.productQuantity;
        for (const key in this.extra) {
          if (this.extra[key].selected) total += (this.extra[key].price * this.extra[key].quantity);
        }
        return total;
      },

      getDuration() {
        let sum = this.duration;

        for (const key in this.extra) {
          if (this.extra[key].selected) sum += this.extra[key].duration;
          if (this.extra[key].selected && this.extra[key].isFastDelivery) {
            sum = this.extra[key].duration;
            break;
          }
        }
        return sum;
      }
    },
    created() {
      this.checkout(this.$store.state.routerParams[0]);
      this.paymentType = this.Constants.PAYMENT_TYPES.CREDIT
    }
  }
</script>

<style scoped lang="scss">
  .checkout-container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;


    .checkout-container-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

  }

  .checkout-header-body {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .checkout-header {
    color: #2d3640;
    font-weight: 500;
    font-size: 28px;
    text-align: left;
  }

  .checkout-inner {
    display: flex;
    width: 1140px;
    margin-top: 50px;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .section-1 {
    flex: 3;
  }

  .section-2 {
    margin-left: 30px;
    flex: 1;
  }

  .card-header {
    font-size: 20px;
    font-weight: 600;

    color: #2d3640;

  }

  .product-info-body {
    border: 1px solid #eaedf2;
    padding: 20px 15px 20px;
    background-color: #fff;
  }

  .info-inner {
    display: flex;
    align-items: flex-start;

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 10px;
      justify-content: space-between;
      min-height: 90px;
    }

    .image {
      width: 130px;
      height: 90px;
    }
  }

  .total {
    color: #2d3640;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-start;
  }

  .info-1 {
    display: flex;
    justify-content: space-between;

    .description {
      color: #2d3640;
      font-weight: 500;
      align-self: flex-start;
      max-width: 380px;
      min-height: 36px;
    }
  }

  .package-detail {

  }

  .quantity-body {
    display: flex;
    align-items: center;
  }

  .quantity-title {


    font-size: 14px;
    font-weight: 500;
    color: rgb(45, 54, 64);
    margin-right: 13px;
  }

  .quantity {
    min-width: 65px;
    width: 65px;
    background-position-x: 47px;
    padding: 5px 10px 5px;
  }

  .commentRating {
    font-weight: 500;
    font-size: 14px;
    color: rgb(255, 183, 0);
    margin-left: 4px;
  }

  .commentCount {
    font-size: 13px;
    font-weight: 400;
    color: rgb(139, 149, 161);
    margin-left: 4px;
  }

  .detail-open-button {
    cursor: pointer;
    color: #26ca9a;
    font-size: 14px;
    font-weight: 500;

    span {
      font-size: 10px;
    }
  }

  .package-detail {
    margin-top: 20px;

    .title {
      font-weight: 500;
      color: #4b4f52;
      font-size: 16px;
    }

    .description {
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
      color: #4b4f52;
    }
  }

  .scope-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 120px;
    width: min-content;
  }

  .content-scope {
    display: flex;
    margin-bottom: 7px;
    width: 250px;
  }

  .content-title {
    color: #2d3640;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
  }

  .content-img {

    margin-right: 8px;
  }

  /*extra*/

  .product-extra-body {
    border: 1px solid #eaedf2;
    padding: 20px 15px 20px;
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .extras-table {
    width: 100%;
    margin-top: 30px;
  }

  tr {
    height: 60px;

    &:hover {
      background-color: #ecf5ff;

    }

    .t-1 {
    }

    .t-2 {
      .row {
        justify-content: center;
      }
    }

    .t-3 {
    }

    td {
      color: #2d3640;
      font-size: 14px;
      line-height: 16px;
      padding: 13px 5px;
      box-sizing: border-box;
      -webkit-transition: background .2s;
      transition: background .2s;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;

      &:first-child {
        text-align: left;
        width: 70%;
      }

      &:nth-child(2) {
        width: 20%;
        text-align: center;
      }

      &:nth-child(3) {
        width: 10%;
        text-align: right;
      }
    }
  }

  .extras {
    display: flex;
    justify-content: space-between;
  }

  .checkbox-box {
    position: relative;
    border: 1px solid #bfc8d2;
    height: 20px;
    width: 20px;
    background-color: #fff;
    margin-right: 10px;

    .checkbox-img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 20px;
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  /*summary*/
  .product-summary-body {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 20px;
    background-color: #fff;
    border: 1px solid #eaedf2;
  }

  .summary-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .item-title {
    font-weight: 300;
    color: #2d3640;
    font-size: 14px;
  }

  .item-value {
    font-weight: 400;
    color: #2d3640;
    font-size: 16px;
  }

  .summary-button {
    margin-top: 48px;
    width: 200px;
    min-width: 200px;
    height: 48px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    align-self: center;

    &:hover {
      background-color: #00a575 !important;
    }


  }

  .tamamla-help-text {
    text-align: center;
    width: 100%;
    margin-top: 12px;
    font-size: 13px;
    font-weight: 300;
    color: #a8a8a8;
  }


  .header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }
</style>
